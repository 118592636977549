const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'returns';
const namespace = 'ui-pdp-icon ui-pdp-icon--return';

const IconReturn = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconReturn.propTypes = {
  className: string,
};

IconReturn.defaultProps = {
  className: null,
};

module.exports = React.memo(IconReturn);
exports = module.exports;
exports.ICON_ID = ICON_ID;
